<!--
 * @Author: your name
 * @Date: 2020-07-09 10:14:07
 * @LastEditTime: 2020-07-09 11:48:23
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \b2b2c-admin\src\page\custom\Detail.vue
-->
<template>
    <div>
        <div class="crumbs">
            <Breadcrumb v-bind:menuList="menuList"></Breadcrumb>
        </div>
        <div class="container">
            <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                <el-tab-pane label="用户信息" name="info">
                    <div>
                        <div class="nav">
                            <span>
                                <img :src="pageInfo.user.avatar" v-if="pageInfo.user.avatar" style="height:100px;width:100px;border-radius:50px;" />
                                <img v-else src="../../assets/img/head.png" style="height:100px;width:100px;border-radius:50px;" />

                            </span>
                            <span class="nav1">

                                <div class="nav11"><span>{{pageInfo.user.username}}</span> </div>
<!--                                 <div class="nav11">
                                    <span>用户ID： {{pageInfo.user.id}}</span>
                                </div>-->
                                <div class="nav11">
                                    <span>手机号： {{pageInfo.user.phone}}</span>
                                </div>

                            </span>
                            <span>
                                <div>
                                   <div class="nav11">
                                      <span> 注册时间：{{pageInfo.user.ctime}}</span>
                                    </div>
                                </div>
                            </span>
                            <span class="nav2">
                                <div>
                                    <el-button type="primary" v-if="pageInfo.user.vip">vip</el-button>
                                    <el-button type="primary" v-if="pageInfo.auth.id" @click="addVisible = true">已实名
                                    </el-button>
                                    <el-button type="primary" v-else>未实名</el-button>

                                    <el-button type="primary">账户状态：{{stateText}}</el-button>
                                    <el-button type="primary">推荐人ID:{{pageInfo.agent_id}}</el-button>
                                </div>
                            </span>
<!--                            <div class="nav3">
                                <div style="font-size: 25px;">
                                    <span style="font-size: 10px;">￥</span>
                                    {{pageInfo.wallet.normal_money}}
                                </div>
                                <div style="margin-top: 15px;text-align: center;">可用提现佣金</div>
                            </div>
                            <div class="nav4">
                                <div style="font-size: 25px;">
                                    <span style="font-size: 10px;">￥</span>
                                    {{pageInfo.wallet.money}}
                                </div>
                                <div style="margin-top: 15px;text-align: center;">可用余额</div>
                            </div>
                            <div class="nav4">
                                <el-button type="primary" @click="giveCoupon">发券</el-button>
                            </div>-->
                        </div>
                      <!-- 客户资产 -->
                      <div class="nav">
                        <div class="nav3">
                          <div style="font-size: 25px;">
                            <span style="font-size: 10px;">￥</span>
                            {{pageInfo.wallet.normal_money}}
                          </div>
                          <div style="margin-top: 15px;text-align: center;">可用提现佣金</div>
                        </div>
                        <div class="nav4">
                          <div style="font-size: 25px;">
                            <span style="font-size: 10px;">￥</span>
                            {{pageInfo.wallet.money}}
                          </div>
                          <div style="margin-top: 15px;text-align: center;">可用余额</div>
                        </div>
                        <div class="nav4">
                          <div style="font-size: 25px;">
                            <span style="font-size: 10px;">￥</span>
                            {{pageInfo.wallet.integral}}
                          </div>
                          <div style="margin-top: 15px;text-align: center;">账户积分</div>
                        </div>
<!--                        <div class="nav4">
                          <el-button type="primary" @click="giveCoupon">发券</el-button>
                        </div>-->
                      </div>
                        <!-- 客户标签 -->
                        <div style="padding-top:30px;">
                            <div style="margin-top: 30px;">
                                客户标签：
                                <el-tag v-for="(item,index) in tagList" :key="index" closable :disable-transitions="true" @close="handleClose(item.name)">{{item.name}}</el-tag>
                                <el-input placeholder="输入内容,回车键确认" v-model="tag" v-if="tagVisible" @keyup.enter.native="handleTagSelect"></el-input>
                                <el-button v-else class="button-new-tag" size="small" @click="showtag">+ 加标签</el-button>
                            </div>
                        </div>
                    </div>
                    <!-- 实名提示框 -->
                    <el-dialog title="实名信息" class="content" :visible.sync="addVisible" width="600px" v-if="addVisible">
                        <el-row class="item">
                            <el-col :span="5">姓名：</el-col>
                            <el-col :span="19">{{pageInfo.auth.username}}</el-col>
                        </el-row>
                        <el-row class="item">
                            <el-col :span="5">身份证号：</el-col>
                            <el-col :span="19">{{pageInfo.auth.number}}</el-col>
                        </el-row>
                        <!-- <el-row class="item">
                            <el-col :span="5">手机号：</el-col>
                            <el-col :span="19">{{pageInfo.auth.number}}</el-col>
                        </el-row>
                        <el-row class="item">
                            <el-col :span="5">提交时间：</el-col>
                            <el-col :span="19">{{pageInfo.auth.number}}</el-col>
                        </el-row>-->
                        <el-row class="item">
                            <el-col :span="12">
                                <img :src="pageInfo.auth.positive" style="width: 200px; height:120px;" />
                            </el-col>
                            <el-col :span="12">
                                <img :src="pageInfo.auth.back" style="width: 200px; height:120px;" />
                            </el-col>
                        </el-row>
                        <!-- <span slot="footer" class="dialog-footer">
                            <el-button type="primary" @click="addVisible = false">审核通过</el-button>
                            <el-button @click="saveData">驳回</el-button>
                        </span>-->
                    </el-dialog>
                </el-tab-pane>
                <el-tab-pane label="消费明细" name="consumption">
                    <el-form :inline="true" class="demo-form-inline">
                        <el-form-item label="订单编号：">
                            <div class="block">
                                <el-input v-model="queryParams.ordersn" placeholder="请输入订单编号" clearable></el-input>
                            </div>
                        </el-form-item>
                        <div style="float: right">
                            <el-button type="primary" @click="loadConsumption()">搜索</el-button>
                        </div>
                    </el-form>
                    <el-table element-loading-text="数据加载中" stripe border ref="multipleTable" :data="consumption.list" style="margin-top:20px">
                        <el-table-column prop="id" label="id" width="150" align="center"></el-table-column>
                        <el-table-column prop="ordersn" label="订单编号" width="200" align="center"></el-table-column>
                        <el-table-column prop="price" label="金额" width="200" align="center"></el-table-column>
                        <el-table-column prop="ctime" label="下单时间" width="300" align="center"></el-table-column>
                        <el-table-column prop="content" label="订单内容" align="center"></el-table-column>
                        <el-table-column prop="state" label="订单状态" width="200" align="center">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                                <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <Pagination v-bind:pageInfo="consumption" v-on:refreshPageSize="(val) => refreshPageSize(val,'consumption')" v-on:refreshPageNum="(val) => refreshPageNum(val,'consumption')" />
                </el-tab-pane>
                <el-tab-pane label="储值明细" name="wallet">
                    <el-form :inline="true" class="demo-form-inline">
                        <el-form-item label="状态：">
                            <el-select v-model="queryParams.state" placeholder="全部" clearable @change="changeQuery('wallet')">
                                <!-- <el-option label="全部" :value="0"></el-option> -->
                                <el-option label="下线" :value="1"></el-option>
                                <el-option label="上线" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <div style="float: right">
                            <el-button type="primary" @click="changeQuery('wallet')">搜索</el-button>
                        </div>
                    </el-form>

                    <el-table element-loading-text="数据加载中" stripe border ref="multipleTable" :data="wallet.list" style="margin-top:20px">
                        <el-table-column prop="id" label="id" width="150" align="center"></el-table-column>
                        <el-table-column prop="price" label="储值金额" width="200" align="center"></el-table-column>
                        <el-table-column prop="pay_time" label="储值时间" width="300" align="center"></el-table-column>
                        <!-- <el-table-column prop="pay_type" label="付款方式" width="100" align="center"></el-table-column> -->
                        <el-table-column prop="content" label="说明" align="center"></el-table-column>
                        <el-table-column prop="state" label="订单状态" width="200" align="center">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                                <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <Pagination v-bind:pageInfo="wallet" v-on:refreshPageSize="(val) => refreshPageSize(val,'wallet')" v-on:refreshPageNum="(val) => refreshPageNum(val,'wallet')" />
                </el-tab-pane>
                <el-tab-pane label="优惠券明细" name="coupon">
                    <el-form :inline="true" class="demo-form-inline">
                        <el-form-item label="优惠券状态：">
                            <el-select v-model="queryParams.state" placeholder="全部" clearable @change="changeQuery('coupon')">
                                <!-- <el-option label="全部" :value="0"></el-option> -->
                                <el-option label="已使用" :value="2"></el-option>
                                <el-option label="未使用" :value="1"></el-option>
                                <el-option label="已过期" :value="3"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="优惠券名称：">
                            <el-input v-model="queryParams.name" placeholder="请输入优惠券名称" clearable></el-input>
                        </el-form-item>
                        <div style="float: right">
                            <el-button type="primary" @click="changeQuery('coupon')">搜索</el-button>
                        </div>
                    </el-form>
                    <el-table element-loading-text="数据加载中" stripe border ref="multipleTable" :data="coupon.list" style="margin-top:20px">
                        <el-table-column prop="id" label="id" width="80" align="center"></el-table-column>
                        <el-table-column prop="ordersn" label="订单编号" align="center"></el-table-column>
                        <el-table-column prop="name" label="优惠券名称" align="center"></el-table-column>
                        <el-table-column prop="price" label="优惠券价格" width="120" align="center"></el-table-column>
                        <el-table-column prop="full_price" label="满减金额" width="120" align="center"></el-table-column>
                        <el-table-column prop="ctime" label="领取时间" width="170" align="center"></el-table-column>
                        <el-table-column prop="utime" label="使用时间" width="170" align="center"></el-table-column>
                        <el-table-column prop="state" label="状态" width="100" align="center">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.state === 2">已使用</el-tag>
                                <el-tag type="primary" v-if="scope.row.state === 1">未使用</el-tag>
                                <el-tag type="primary" v-if="scope.row.state === 3">已过期</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <Pagination v-bind:pageInfo="coupon" v-on:refreshPageSize="(val) => refreshPageSize(val,'coupon')" v-on:refreshPageNum="(val) => refreshPageNum(val,'coupon')" />
                </el-tab-pane>
                <el-tab-pane label="银行卡" name="bank">
                    <el-form :inline="true" class="demo-form-inline">
                        <el-form-item label="银行卡状态：">
                            <el-select v-model="queryParams.state" placeholder="全部" clearable @change="changeQuery('bank')">
                                <el-option label="下线" :value="1"></el-option>
                                <el-option label="上线" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <div style="float: right">
                            <el-button type="primary" @click="changeQuery('bank')">搜索</el-button>
                        </div>
                    </el-form>
                    <el-table element-loading-text="数据加载中" stripe border ref="multipleTable" :data="bank.list" style="margin-top:20px">
                        <!-- <el-table-column type="selection"></el-table-column> -->
                        <el-table-column prop="id" label="ID" width="100" align="center"></el-table-column>
                        <el-table-column prop="bankname" label="银行卡名称" align="center"></el-table-column>
                        <el-table-column prop="content" label="开户行地址" width="400" align="center"></el-table-column>
                        <el-table-column prop="state" label="状态" width="400" align="center">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.state === 1">下线</el-tag>
                                <el-tag type="primary" v-if="scope.row.state === 2">上线</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <Pagination v-bind:pageInfo="bank" v-on:refreshPageSize="(val) => refreshPageSize(val,'bank')" v-on:refreshPageNum="(val) => refreshPageNum(val,'bank')" />
                </el-tab-pane>

                <el-tab-pane label="提现记录" name="carry">
                    <el-form :inline="true" class="demo-form-inline">
                        <el-form-item label="提现状态：">
                            <el-select v-model="queryParams.state" placeholder="全部" clearable @change="changeQuery('carry')">
                                <el-option label="待审核" :value="1"></el-option>
                                <el-option label="审核通过" :value="2"></el-option>
                                <el-option label="驳回" :value="3"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="订单编号：">
                            <el-input v-model="queryParams.ordersn" placeholder="请输入订单编号" clearable></el-input>
                        </el-form-item>
                        <div style="float: right">
                            <el-button type="primary" @click="changeQuery('carry')">搜索</el-button>
                        </div>
                    </el-form>
                    <el-table element-loading-text="数据加载中" stripe border ref="multipleTable" :data="carry.list" style="margin-top:20px">
                        <el-table-column prop="id" label="id" width="150" align="center"></el-table-column>
                        <el-table-column prop="ordersn" label="订单编号" width="200" align="center"></el-table-column>
                        <el-table-column prop="username" label="提现人" width="200" align="center"></el-table-column>
                        <el-table-column prop="price" label="提现金额" width="200" align="center"></el-table-column>
                        <el-table-column prop="content" label="说明" align="center"></el-table-column>
                        <el-table-column prop="state" label="提现状态" width="200" align="center">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.state === 1">待审核</el-tag>
                                <el-tag type="primary" v-if="scope.row.state === 2">审核通过</el-tag>
                                <el-tag type="primary" v-if="scope.row.state === 3">驳回</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <Pagination v-bind:pageInfo="carry" v-on:refreshPageSize="(val) => refreshPageSize(val,'carry')" v-on:refreshPageNum="(val) => refreshPageNum(val,'carry')" />
                </el-tab-pane>
                <el-tab-pane label="用户订单" name="order">
                    <el-form :inline="true" class="demo-form-inline">
                        <el-form-item label="订单状态">
                            <el-select v-model="queryParams.pay_state" placeholder="全部" clearable @change="changeQuery('order')">
                                <el-option label="未付款" :value="0"></el-option>
                                <el-option label="已付款" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="订单编号：">
                            <el-input v-model="queryParams.ordersn" placeholder="请输入订单编号" clearable></el-input>
                        </el-form-item>
                        <div style="float: right">
                            <el-button type="primary" @click="changeQuery('order')">搜索</el-button>
                        </div>
                    </el-form>
                    <el-table element-loading-text="数据加载中" stripe border ref="multipleTable" :data="order.list" style="margin-top:20px">
                        <el-table-column prop="id" label="id" width="150" align="center"></el-table-column>
                        <el-table-column prop="ordersn" label="订单编号" width="230" align="center"></el-table-column>
                        <el-table-column prop="price" label="订单金额" width="150" align="center"></el-table-column>
                        <el-table-column prop="pay_time" label="付款时间" width="200" align="center"></el-table-column>
                        <el-table-column prop="pay_type" label="付款方式" align="center"></el-table-column>
                        <el-table-column label="订单状态" align="center">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.pay_state === 0">未付款</el-tag>
                                <el-tag type="primary" v-if="scope.row.pay_state === 2">已付款</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <Pagination v-bind:pageInfo="order" v-on:refreshPageSize="(val) => refreshPageSize(val,'order')" v-on:refreshPageNum="(val) => refreshPageNum(val,'order')" />
                </el-tab-pane>

                <el-tab-pane label="用户推荐订单" name="orderuser">
                    <el-form :inline="true" class="demo-form-inline">
                        <el-form-item label="订单状态">
                            <el-select v-model="queryParams.pay_state" placeholder="全部" clearable @change="changeQuery('orderuser')">
                                <el-option label="未付款" :value="0"></el-option>
                                <el-option label="已付款" :value="2"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="订单编号：">
                            <el-input v-model="queryParams.ordersn" placeholder="请输入订单编号" clearable></el-input>
                        </el-form-item>
                        <div style="float: right">
                            <el-button type="primary" @click="changeQuery('orderuser')">搜索</el-button>
                        </div>
                    </el-form>
                    <el-table element-loading-text="数据加载中" stripe border ref="multipleTable" :data="orderuser.list" style="margin-top:20px">
                        <el-table-column prop="id" label="id" width="150" align="center"></el-table-column>
                        <el-table-column prop="ordersn" label="订单编号" width="230" align="center"></el-table-column>
                        <el-table-column prop="price" label="订单金额" width="150" align="center"></el-table-column>
                        <el-table-column prop="pay_time" label="付款时间" width="200" align="center"></el-table-column>
                        <el-table-column prop="pay_type" label="付款方式" align="center"></el-table-column>
                        <el-table-column label="订单状态" align="center">
                            <template slot-scope="scope">
                                <el-tag type="info" v-if="scope.row.pay_state === 0">未付款</el-tag>
                                <el-tag type="primary" v-if="scope.row.pay_state === 2">已付款</el-tag>
                            </template>
                        </el-table-column>
                    </el-table>
                    <!-- 分页 -->
                    <Pagination v-bind:pageInfo="orderuser" v-on:refreshPageSize="(val) => refreshPageSize(val,'orderuser')" v-on:refreshPageNum="(val) => refreshPageNum(val,'orderuser')" />
                </el-tab-pane>

                <el-tab-pane label="上级推荐人" name="userpromoto">
                    <el-row>
                        <el-col  :span="11" v-if="pageInfo.agent !==undefined && pageInfo.agent.id >0">
                            <div class="nav">
                                <div>
                                    <el-tag type="success">直接推荐人</el-tag>
                                </div>
                                <div>
                                    <span>
                                        <img :src="pageInfo.agent.avatar" v-if="pageInfo.agent.avatar"
                                             style="height:100px;width:100px;border-radius:50px;"/>
                                        <img v-else src="../../assets/img/head.png"
                                             style="height:100px;width:100px;border-radius:50px;"/>
                                    </span>
                                </div>
                                <span class="nav1">
                                <div class="nav11">
                                    <el-link type="primary"  :href="'/static/#/custom-Detail?id='+pageInfo.agent.id"
                                             style="font-size: 18px;font-weight: bold">{{ pageInfo.agent.username }}
                                    </el-link>
                                </div>
                                <div class="nav11"><span>用户ID： {{ pageInfo.agent.id }}</span></div>
                                <div class="nav11"><span>手机号： {{ pageInfo.agent.phone }}</span></div>
                            </span>
                                <span>
                            <div class="nav11">  <span> 注册时间：{{ pageInfo.agent.ctime }}</span></div>
                        </span>
                            </div>
                        </el-col>
                        <el-col  v-if="pageInfo.once_agent !==undefined && pageInfo.once_agent.id >0" :span="11">
                            <div class="nav">
                                <div>
                                    <el-tag type="primary">间接推荐人</el-tag>
                                </div>
                                <div>
                                    <span>
                                        <img :src="pageInfo.once_agent.avatar" v-if="pageInfo.once_agent.avatar"
                                             style="height:100px;width:100px;border-radius:50px;"/>
                                        <img v-else src="../../assets/img/head.png"
                                             style="height:100px;width:100px;border-radius:50px;"/>
                                    </span>
                                </div>
                                <span class="nav1">
                                <div class="nav11">
                                    <el-link type="primary"  :href="'/static/#/custom-Detail?id='+pageInfo.once_agent.id"
                                             style="font-size: 18px;font-weight: bold">{{ pageInfo.once_agent.username }}
                                    </el-link>
                                </div>
                                <div class="nav11"><span>用户ID： {{ pageInfo.once_agent.id }}</span></div>
                                <div class="nav11"><span>手机号： {{ pageInfo.once_agent.phone }}</span></div>
                            </span>
                                <span>
                            <div class="nav11"><span> 注册时间：{{ pageInfo.once_agent.ctime }}</span></div>
                        </span>
                            </div>
                        </el-col>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
            <Discountcom :addVisible="addVisibles" :title="title" @toggleAdd="toggleAdd" @getAddGood="getAddGood">
            </Discountcom>
        </div>
    </div>
</template>
<script>
import Breadcrumb from '@/components/Breadcrumb';
import Pagination from '@/components/Pagination';
import {request} from '@/common/request';
import {deal} from '@/common/main';
import Discountcom from '@/components/Discountcom';

export default {
    name: 'seller',
    components: {
        Breadcrumb,
        Pagination,
        Discountcom
    },
    data() {
        return {
            id: 0,
            title: "选择商品",
            url: '',
            state: '',
            stateText: '',
            tagList: [],
            tagVisible: false,
            tag: '',
            pageInfo: {
                user: {},
                tag: {},
                wallet: {},
                auth: {},
                vip: {}
            },//账户信息
            consumption: {
            },//消费信息
            wallet: {
            }, //储值信息
            bank: {
            }, //银行卡信息
            carry: {
            },//提现信息
            order: {
            },//用户订单
            coupon: {
            },//优惠券明细
            orderuser: {
            },//用户推荐订单
            addVisible: false,
            addVisibles: false,
            menuList: ['用户', '用户详情'],
            activeName: 'info',
            queryParams: {
                page: 1,
                pagesize: 10
            },
            discountList: [],
        };
    },
    watch: {
        $route: {
            handler(to, from) {
                if (to.query.id && to.query.id != this.id) {
                    this.id = to.query.id
                    this.queryParams.uid = to.query.id;
                    this.loadData();
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        toggleAdd(val) {
            this.addVisibles = val
        },
        getAddGood(val) {
            if (val) {
                let ids = []
                ids = val.map(item =>
                    item.id
                )
                let coupon_ids = ids.join(',')
                request.post('/coupon/give/coupon/add', { uid: this.queryParams.uid, coupon_ids: coupon_ids }).then(ret => {
                    if (ret.code == 1) {
                        this.$message.success('送券成功');
                    } else {
                        this.$message.error(ret.msg);
                    }
                });
            }
        },
        // 发券
        giveCoupon() {
            this.addVisibles = true;
        },
        // 关闭tag
        handleClose(name) {
            request.post('/account/tag/remove', { uid: this.queryParams.uid, name: name }).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('删除成功');
                    this.loadData();
                } else {
                    this.$message.error(ret.msg);
                }
            });
            // this.tagList.splice(index, 1);
            this.state = ''
        },
        showtag() {
            this.tagVisible = true;
        },

        // 新增tag
        handleTagSelect() {

            request.post('/account/tag/add', { uid: this.queryParams.uid, name: this.tag }).then(ret => {
                if (ret.code == 1) {
                    this.$message.success('操作成功');
                    this.loadData();
                    this.confirmVisible = false;
                } else {
                    this.$message.error(ret.msg);
                }
            });
            this.tag = null
        },
        //用户信息
        loadData() {
            this.loading = true;
            request.get('/account/detail', { id: this.queryParams.uid }).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.pageInfo = ret.data;
                    this.tagList = ret.data.tag
                    switch (ret.data.user.state) {
                        case 0: this.stateText = "待审核"
                            break;
                        case 1: this.stateText = " 审核中"
                            break;
                        case 2: this.stateText = " 正常"
                            break;
                        case 4: this.stateText = " 刪除"
                            break;
                        case -1: this.stateText = " 已注销"
                            break;
                    }
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //消费列表
        loadConsumption() {
            this.loading = true;
            request.get('/wallet/user/consumption/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.consumption = ret.data;
                    this.consumption.list = ret.data.list;
                    this.consumption.list.map(function (val) {
                        val.pay_time = deal.timestamp(val.pay_time, 'unix');
                        val.ctime = deal.timestamp(val.ctime, 'unix');

                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //储值明细列表
        loadWallet() {
            this.loading = true;
            request.get('/wallet/user/order/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.wallet = ret.data;
                    this.wallet.list = ret.data.list;
                    this.wallet.list.map(function (val) {
                        val.pay_time = deal.timestamp(val.pay_time, 'unix');
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //优惠券明细列表
        loadCoupon() {
            this.loading = true;
            request.get('/coupon/user/detailed', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.coupon = ret.data;
                    this.coupon.list = ret.data.list;
                    this.coupon.list.map(function (val) {
                        val.ctime = deal.timestamp(val.ctime, 'unix');
                        val.stime = deal.timestamp(val.stime, 'unix');
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //提现明细列表
        loadCarry() {
            this.loading = true;
            request.get('/wallet/user/carry/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.carry = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        // 银行卡明细
        loadBank() {
            this.loading = true;
            request.get('/wallet/user/bank/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.bank = ret.data;
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //用户订单
        loadOrder() {
            this.loading = true;
            request.get('/order/user/list', this.queryParams).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.order = ret.data;
                    this.order.list = ret.data.list
                    this.order.list.map(function (val) {
                        if (val.pay_time == 0) {
                            val.pay_time = ""
                        } else {
                            val.pay_time = deal.timestamp(val.pay_time, 'unix');

                        }
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },
        //用户推荐订单
        loadOrderuser() {
            this.loading = true;
            request.get('/order/user/list', { page: 1, pagesize: 10, agent_id: this.queryParams.uid, }).then(ret => {
                if (ret.code == 1) {
                    this.loading = false;
                    this.orderuser = ret.data;
                    this.orderuser.list = ret.data.list
                    this.orderuser.list.map(function (val) {
                        if (val.pay_time == 0) {
                            val.pay_time = ""
                        } else {
                            val.pay_time = deal.timestamp(val.pay_time, 'unix');

                        }
                    })
                } else {
                    this.$message.error(ret.msg);
                }
            });
        },

        handleClick(tab, event) {
            this.queryParams = {
                uid: this.id,
                page: 1,
                pagesize: 10
            },
                this.fomart(tab.paneName)
        },

        //分页
        changeQuery(text) {
            this.queryParams.page = 1;
            this.fomart(text)
        },
        refreshPageSize(val, text) {
            this.queryParams.pagesize = val;
            this.fomart(text)
        },
        refreshPageNum(val, text) {
            this.queryParams.page = val;
            this.fomart(text)
        },
        fomart(text) {
            switch (text) {
                case 'info': this.loadData()
                    break;
                case 'consumption': this.loadConsumption()
                    break;
                case 'wallet': this.loadWallet()
                    break;
                case 'coupon': this.loadCoupon()
                    break;
                case 'bank': this.loadBank()
                    break;
                case 'carry': this.loadCarry()
                    break;
                case 'order': this.loadOrder()
                    break;
                case 'orderuser': this.loadOrderuser()
                    break;
                case 'userpromoto':
                    break;
            }
        },
    }
};
</script>

<style scoped>
.el-tag + .el-tag {
    margin-left: 10px;
}

.button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
}

.input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
}

.nav {
    display: flex;
    margin-top: 30px;
    background: #ededed;
    padding: 10px;
}

.nav1 {
    margin-left: 20px;
    margin-top: 20px;
}

.nav2 {
    margin-left: 20px;
    margin-top: 20px;
}

.nav3 {
    margin-left: 30px;
    margin-top: 20px;
}

.nav4 {
    margin-left: 30px;
    margin-top: 20px;
}

.nav11 {
    font-size: 19px;
}

.nav12 {
    font-size: 19px;
}

.centres1 {
    display: flex;
    margin-top: 15px;
}

.centres1 div {
    flex: 1;
}

.centres {
    margin-top: 30px;
    background: #eeeeee;
    padding: 20px;
}

.base {
    display: flex;
    margin-top: 50px;
}

.base div {
    flex: 1;
}

.el-tag--medium {
    height: 32px;
    padding-top: 2px;
}

.content >>> .el-dialog__header {
    background: #dddddd;
    border-bottom: 1px solid #eeeeee;
}

.item {
    margin: 0 auto;
    padding: 10px;
    font-size: 18px;
}
</style>
